import React from 'react'
import ReactDOM from 'react-dom/client'
import {ThemeProvider} from 'styled-components'
import {Theme} from 'utils/theme'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import PersonalDetailsStep from 'form/PersonalDetailsStep'
import AdditionalCategoriesStep from 'form/AdditionalCategoriesStep'
import SummaryStep from 'form/SummaryStep'
import Farewell from 'form/Farewell'
import {AppCenterContextProvider} from 'AppContext'
import 'utils/global.css'
import {Toaster} from 'react-hot-toast'
import Cookies from 'components/Cookies'
import Login from 'admin/pages/Login'
import Entries from 'admin/pages/Entries'
import Candidates from 'admin/pages/Candidates'
import AddSuccessCandidate from 'admin/pages/AddSuccessCandidate'
import EditSuccessCandidate from 'admin/pages/EditSuccessCandidate'
import 'react-quill/dist/quill.snow.css'
import "react-quill/dist/quill.core.css"
import Booklets from 'admin/pages/Booklets'
import AddInnovationCandidate from 'admin/pages/AddInnovationCandidate'
import EditInnovationCandidate from 'admin/pages/EditInnovationCandidate'
import AddCharityCandidate from 'admin/pages/AddCharityCandidate'
import EditCharityCandidate from 'admin/pages/EditCharityCandidate'
import SingleCandidatePreview from 'admin/pages/SingleCandidatePreview'
import BookletPreview from 'admin/components/BookletPreview'
import Voters from 'admin/pages/Voters'
import Voting from 'admin/pages/Voting'
import NomineePreview from 'admin/pages/NomineePreview'
import InitialVoting from 'admin/pages/InitialVoting'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <PersonalDetailsStep />
  },
  {
    path: '/formularze',
    element: <AdditionalCategoriesStep />
  },
  {
    path: '/podsumowanie',
    element: <SummaryStep />
  },
  {
    path: '/podziekowanie',
    element: <Farewell />
  },
  {
    path: '/adminAward',
    element: <Login />
  },
  {
    path: '/adminAward/zgloszenia',
    element: <Entries />
  },
  {
    path: '/adminAward/kandydaci',
    element: <Candidates />
  },
  {
    path: '/adminAward/kandydaci/sukces/dodaj',
    element: <AddSuccessCandidate />
  },
  {
    path: '/adminAward/kandydaci/zobacz/:id',
    element: <SingleCandidatePreview />
  },
  {
    path: '/adminAward/kandydaci/sukces/edytuj/:id',
    element: <EditSuccessCandidate />
  },
  {
    path: '/adminAward/kandydaci/innowacje/dodaj',
    element: <AddInnovationCandidate />
  },
  {
    path: '/adminAward/kandydaci/innowacje/edytuj/:id',
    element: <EditInnovationCandidate />
  },
  {
    path: '/adminAward/kandydaci/dzialalnosc-spoleczna/dodaj',
    element: <AddCharityCandidate />
  },
  {
    path: '/adminAward/kandydaci/dzialalnosc-spoleczna/edytuj/:id',
    element: <EditCharityCandidate />
  },
  {
    path: '/adminAward/broszury',
    element: <Booklets />
  },
  {
    path: '/broszura/:type',
    element: <BookletPreview />
  },
  {
    path: '/adminAward/glosujacy',
    element: <Voters />
  },
  {
    path: '/kapitula/:code/:id',
    element: <NomineePreview />
  },
  {
    path: '/preselekcja/:code',
    element: <InitialVoting />
  },
  {
    path: '/preselekcja/:code/:id',
    element: <NomineePreview />
  },
  {
    path: '/kapitula/:code',
    element: <Voting />
  }
])

root.render(
  <ThemeProvider theme={Theme}>
    <Toaster />
    <AppCenterContextProvider>
      <RouterProvider router={router} />
      <div id='modal-portal' />
      <Cookies />
    </AppCenterContextProvider>
  </ThemeProvider>
)
