import React, { useEffect, useState } from 'react'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import CandidatesTable, { CandidateTableRowTypes } from 'admin/components/CandidatesTable'

export interface CandidateTypes {
  id: string
  code: string
  name: string
  nominatingPerson: string
  nominatingInstitution: string
  description: string
  type: string
}

export interface stateTypes {
  success: CandidateTableRowTypes[] | []
  innovation: CandidateTableRowTypes[] | []
  charity: CandidateTableRowTypes[] | []
}

const initialValues = {
  success: [],
  innovation: [],
  charity: []
}

const Candidates = () => {
  const [candidates, setCandidates] = useState<stateTypes>(initialValues)
  const navigate = useNavigate()

  const getCandidates = async () => {
    try {
      const response = await supabase
        .from('candidates')
        .select()

      const data = response.data as CandidateTableRowTypes[]

      const success = data.filter(candidate => candidate.type === 'success')
      const innovation = data.filter(candidate => candidate.type === 'innovation')
      const charity = data.filter(candidate => candidate.type === 'charity')

      setCandidates({
        success,
        innovation,
        charity
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getCandidates()
  }, [])

  return (
    <AdminPageWrapper>
      <Header>
        <Title>
          Lista kandydatów
        </Title>
        <Button variant='outlined' onClick={() => navigate('/broszura/wszyscy')}>Zobacz broszurę zbiorczą</Button>
      </Header>
      <TableWrapper>
        <TableTitle>
          Sukces
          <ButtonsWrapper>
            <Button variant='outlined' onClick={() => navigate('/broszura/sukces')}>Zobacz broszurę</Button>
            <Button variant='outlined' onClick={() => navigate('/adminAward/kandydaci/sukces/dodaj')}>Dodaj kandydata</Button>
          </ButtonsWrapper>
        </TableTitle>
        <CandidatesTable
          candidates={candidates.success}
          onRemove={getCandidates}
          slug='sukces'
        />
      </TableWrapper>
      <TableWrapper>
        <TableTitle>
          Innowacje
          <ButtonsWrapper>
            <Button variant='outlined' onClick={() => navigate('/broszura/innowacje')}>Zobacz broszurę</Button>
            <Button variant='outlined' onClick={() => navigate('/adminAward/kandydaci/innowacje/dodaj')}>Dodaj kandydata</Button>
          </ButtonsWrapper>
        </TableTitle>
        <CandidatesTable
          candidates={candidates.innovation}
          onRemove={getCandidates}
          slug='innowacje'
        />
      </TableWrapper>
      <TableWrapper>
        <TableTitle>
          Działalność społeczna
          <ButtonsWrapper>
            <Button variant='outlined' onClick={() => navigate('/broszura/dzialalnosc-spoleczna')}>Zobacz broszurę</Button>
            <Button variant='outlined' onClick={() => navigate('/adminAward/kandydaci/dzialalnosc-spoleczna/dodaj')}>Dodaj kandydata</Button>
          </ButtonsWrapper>
        </TableTitle>
        <CandidatesTable
          candidates={candidates.charity}
          onRemove={getCandidates}
          slug='dzialalnosc-spoleczna'
        />
      </TableWrapper>
    </AdminPageWrapper>
  )
}

export default Candidates

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

const Title = styled.div`
  font-size: 24px;
`

const TableWrapper = styled.div`
  margin: 40px 0;
`

const TableTitle = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`
