import React, { useEffect, useState } from 'react'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import { useNavigate } from 'react-router-dom'
import { IconEye, IconPencil } from '@tabler/icons-react'
import { Button, Tooltip } from '@mui/material'
import { CandidateTypes } from 'admin/pages/Candidates'

export interface BookletTypes {
  id: string
  code: string
  name: string
  success: CandidateTypes[]
  innovations: CandidateTypes[]
  charity: CandidateTypes[]
}

const Booklets = () => {
  const [candidates, setBooklets] = useState<BookletTypes[] | []>([])
  const navigate = useNavigate()

  const getBooklets = async () => {
    try {
      const response = await supabase
        .from('booklets')
        .select()

      const data = response.data as BookletTypes[]

      setBooklets(data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getBooklets()
  }, [])

  return (
    <AdminPageWrapper>
      <Header>
        <Title>
          Lista broszur
        </Title>
        <Button variant='outlined' onClick={() => navigate('/adminAward/kandydaci/dodaj')}>Dodaj broszurę</Button>
      </Header>
      <Table>
        <thead>
        <tr>
          <th>Nazwa broszury</th>
          <th>Sukces</th>
          <th>Innowacje</th>
          <th>Działalność charytatywna</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {candidates.map(candidate => (
          <tr key={candidate.id}>
            <td>{candidate.name}</td>
            <td>---</td>
            <td>---</td>
            <td>---</td>
            <td>
              <Actions>
                <Tooltip title='Zobacz podgląd'>
                  <IconWrapper>
                    <IconEye />
                  </IconWrapper>
                </Tooltip>
                <Tooltip title='Edytuj'>
                  <IconWrapper>
                    <IconPencil />
                  </IconWrapper>
                </Tooltip>
              </Actions>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </AdminPageWrapper>
  )
}

export default Booklets

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

const Title = styled.div`
  font-size: 24px;
`

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
  
  th {
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
  }
  
  td {
    padding: 8px 16px;
    border-bottom: #ccc;
    border-bottom: 1px solid #f2f2f2;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  cursor: pointer;
`
