import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  charityCandidateFields,
  innovationCandidateFields,
  successCandidateFields
} from 'utils/candidates'
import { isNotNilOrEmpty } from 'utils/ramda'
import PreviewTable from 'admin/components/PreviewTable'
import { CandidateTableRowTypes } from 'admin/components/CandidatesTable'
import { mobileCss } from 'utils/theme'

const CandidatePreview = ({ candidate }: { candidate: CandidateTableRowTypes | null }) => {
  const content = useMemo(() => {
    if (candidate) {
      const fields = candidate.type === 'success'
        ? successCandidateFields
        : candidate.type === 'innovation'
          ? innovationCandidateFields
          : charityCandidateFields

      return fields

        .filter(field => {


          const fieldsToOmit = ['nominee', 'sortingName', 'nominatedyBy', 'nominatedyByCompany', 'tableComments']
          // @ts-ignore
          return isNotNilOrEmpty(candidate[field.key]) && !fieldsToOmit.includes(field.key)})

        .map(field => {
          if (field.type === 'input' || field.type === 'wysiwyg') {

            if (field.key === 'website') {

              return (
                <Section>
                  <Label>{field.label}</Label>
                  {/* @ts-ignore */}
                  <SectionValue><a target='_blank' href={candidate[field.key]}>{candidate[field.key]}</a></SectionValue>
                </Section>
              )
            } else {
              return (
                <Section>
                  <Label>{field.label}</Label>
                  {/* @ts-ignore */}
                  <SectionValue dangerouslySetInnerHTML={{ __html: candidate[field.key] }} />
                </Section>
              )
            }
          }

          if (field.type === 'boolean') {
            // @ts-ignore
            const value = candidate[field.key]
            // const displayValue =
            //   value === 'no-info' ? 'Brak informacji' :
            //     typeof value === 'boolean' ? (value ? 'TAK' : 'NIE') : 'NIE'
            const displayValue =
              value === 'no-info' ? 'Brak informacji' :
                value === 'true' ? 'TAK' :
                  value === 'false' ? 'NIE' :
                    'NIE';

            return (

              <Section>
                <Label>{field.label}</Label>
                {/* @ts-ignore */}
                <SectionValue>
                  {displayValue}
                </SectionValue>
              </Section>
            )
          }

          if (field.type === 'boolean-simple') {
            // @ts-ignore
            const value = candidate[field.key]

            if (field.key === 'consent' && value) {

              return (
                <Section>
                  <Label>Udało się pozyskać zgodę kandydata na udział w konkursie</Label>
                </Section>
              )
            } else if (field.key === 'collatedBy') {

              return (
                <Section>
                  <Label>{field.label}</Label>
                </Section>
              )
            }

            return null;
          }

          if (field.type === 'table') {
            // @ts-ignore
            const data = candidate[field.key]

            if (Object.values(data).some(el => isNotNilOrEmpty(el))) {
              return (
                <Section>
                  <Label>{field.label}</Label>
                  <PreviewTable data={data} comments={candidate?.tableComments} />
                </Section>
              )
            }

          }

          return null
        })

    } else {
      return <div />
    }
  }, [candidate])

  return (
    <CandidateWrapper>
      <Name>{candidate?.nominee}</Name>
      {candidate?.nominatedBy ? <>
        <Divider />
        <NominationInfo>
        <NominationInfoLabel>
          Osoba nominująca
        </NominationInfoLabel>
        <NominationInfoValue>
          {candidate?.nominatedBy}
        </NominationInfoValue>
      </NominationInfo>
      </> : null}
      {candidate?.nominatedByCompany ? <NominationInfo>
        <NominationInfoLabel>
          Instytucja nominująca
        </NominationInfoLabel>
        <NominationInfoValue>
          {candidate?.nominatedByCompany}
        </NominationInfoValue>
      </NominationInfo> : null}
      <Divider />
      <Wrapper>
        {content}
      </Wrapper>
    </CandidateWrapper>
  )
}

export default CandidatePreview

const Wrapper = styled.div`
  padding: 0;
  
  p {
    margin: 2px;
  }
`

const CandidateWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 48px;
  
  ${mobileCss(`
    font-size: 26px;
  `)};
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #b3b3b3;
  margin: 24px 0;
`

const NominationInfo = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

const NominationInfoLabel = styled.div`
  text-transform: uppercase;
  color: #737373;
  margin-bottom: 4px;
`

const NominationInfoValue = styled.div`
  color: #999999;
`

const Section = styled.div`
  margin: 32px 0;
`

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #444;
`

const SectionValue = styled.div`
  color: #999;
`
