import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { supabase } from 'utils/supabase'
import toast from 'react-hot-toast'
import AppContext from 'AppContext'
import { useNavigate } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
// @ts-ignore
import logo from 'assets/prb-logo.png'

const defaultValues = {
  email: '',
  password: ''
}

const Login = () => {
  const [credentials, setCredentials] = useState(defaultValues)
  const { saveUserData } = useContext(AppContext)

  const handleValueChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials(prev => ({ ...prev, [name]: e.target.value }))
  }

  useEffect(() => {
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser()

      if (user) {
        saveUserData(user)
        window.location.replace('/adminAward/kandydaci')
      }
    }

    getUser()
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const { data, error } = await supabase.auth.signInWithPassword(credentials)

      if (data.user) {
        saveUserData(data.user)
        window.location.replace('/adminAward/kandydaci')
      }

      if (error) {
        toast.error('Błąd logowania')
      }
    } catch (err) {
      toast.error('Błąd logowania')
      console.error(err)
    }
  }

  return (
    <Wrapper>
      <Logo src={logo} alt='prb' />
      <Form onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          value={credentials.email}
          onChange={handleValueChange('email')}
          label='Email'
          variant='outlined'
        />
        <TextField
          required
          type='password'
          fullWidth
          value={credentials.password}
          onChange={handleValueChange('password')}
          label='Hasło'
          variant='outlined'
        />
        <ButtonWrapper>
          <Button variant='contained' type='submit'>
            Zaloguj
          </Button>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  )
}

export default Login

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 300px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px;
  text-align: center;
`
