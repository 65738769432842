import React from 'react'
import { Tooltip } from '@mui/material'
import { IconEye, IconPencil } from '@tabler/icons-react'
import RemoveCandidateModal from 'admin/components/RemoveCandidateModal'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'

export interface CandidateTableRowTypes {
  id: string
  sortingName: string
  type: string
  nominee: string
  nominatedBy: string
  nominatedByCompany: string
  tableComments?: string
}

interface Candidates {
  candidates: CandidateTableRowTypes[]
  onRemove: () => void
  slug: string
}

const CandidatesTable = ({ candidates, onRemove, slug }: Candidates) => {
  const navigate = useNavigate()

  const sortedCandidates = candidates
    .slice() // Create a copy to avoid mutating the original array
    .sort((a, b) => (a.sortingName > b.sortingName ? 1 : -1))

  return isNotNilOrEmpty(sortedCandidates) ? (
    <Table>
      <thead>
      <tr>
        <th>Nazwa kandydata</th>
        <th>Osoba zgłaszająca</th>
        <th>Instytucja zgłaszająca</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {sortedCandidates.map(candidate => (
        <tr key={candidate.id}>
          <td>{candidate?.nominee || '---'}</td>
          <td>{candidate?.nominatedBy || '---'}</td>
          <td>{candidate.nominatedByCompany || '---'}</td>
          <td>
            <Actions>
              <Tooltip title='Zobacz podgląd'>
                <IconWrapper onClick={() => {
                  navigate(`/adminAward/kandydaci/zobacz/${candidate.id}`)
                }}>
                  <IconEye />
                </IconWrapper>
              </Tooltip>
              <Tooltip title='Edytuj'>
                <IconWrapper onClick={() => {
                  navigate(`/adminAward/kandydaci/${slug}/edytuj/${candidate.id}`)
                }}>
                  <IconPencil />
                </IconWrapper>
              </Tooltip>
              <RemoveCandidateModal id={candidate.id} onRemove={onRemove} />
            </Actions>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  ) : (
    <div>Brak kandydatów w tej kategorii</div>
  )
}

export default CandidatesTable


const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
  
  th {
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
  }
  
  td {
    padding: 8px 16px;
    border-bottom: #ccc;
    border-bottom: 1px solid #f2f2f2;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  cursor: pointer;
`
