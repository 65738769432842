import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import { CandidateTableRowTypes } from 'admin/components/CandidatesTable'
import CandidatePreview from 'admin/components/CandidatePreview'
import { useParams } from 'react-router-dom'
import { mobileCss } from 'utils/theme'

interface stateTypes {
  success: CandidateTableRowTypes[] | []
  innovation: CandidateTableRowTypes[] | []
  charity: CandidateTableRowTypes[] | []
}

const initialValues = {
  success: [],
  innovation: [],
  charity: []
}

const BookletPreview = () => {
  const [candidates, setCandidates] = useState<stateTypes>(initialValues)
  const { type } = useParams()

  const typeKey = type === 'innowacje'
    ? 'innovation'
    : type === 'sukces'
      ? 'success'
      : type === 'dzialalnosc-spoleczna'
        ? 'charity'
        : 'all'

  const typeName = type === 'innowacje'
    ? 'INNOWACJE'
    : type === 'sukces'
      ? 'SUKCES'
      : 'DZIAŁALNOŚĆ SPOŁECZNA'

  const getCandidates = async () => {
    try {
      const response = await supabase
        .from('candidates')
        .select()

      const data = response.data as CandidateTableRowTypes[]

      const success = data.filter(candidate => candidate.type === 'success')
      const innovation = data.filter(candidate => candidate.type === 'innovation')
      const charity = data.filter(candidate => candidate.type === 'charity')

      setCandidates({
        success,
        innovation,
        charity
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getCandidates()
  }, [])

  return (
    <PreviewWrapper>
      {
        typeKey === 'all'
          ? (
            <div>
              <Header>
                Kandydaci w kategorii SUKCES
              </Header>
                <Wrapper>
                  {candidates.success.map(candidate => (
                      <CandidatePreview candidate={candidate} />
                  ))}
                </Wrapper>
              <Header>
                Kandydaci w kategorii INNOWACJE
              </Header>
                <Wrapper>
                  {candidates.innovation.map(candidate => (
                      <CandidatePreview candidate={candidate} />
                  ))}
                </Wrapper>
              <Header>
                Kandydaci w kategorii DZIAŁALNOŚĆ SPOŁECZNA
              </Header>
                <Wrapper>
                  {candidates.charity.map(candidate => (
                      <CandidatePreview candidate={candidate} />
                  ))}
                </Wrapper>
            </div>
          )
          : (
            <div>
              <Header>
                Kandydaci w kategorii {typeName}
              </Header>
              <Wrapper>
                {candidates[typeKey].map(candidate => (
                  <CandidatePreview candidate={candidate} />
                ))}
              </Wrapper>
            </div>
          )
      }
    </PreviewWrapper>
  )
}

export default BookletPreview

const PreviewWrapper = styled.div`
  padding: 16px;
  
  ${mobileCss(`
    width: 90%;
  `)};
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
`

const Header = styled.div`
  margin-bottom: 50px;
  font-size: 34px;
  color: #444;
  
  &:not(:first-of-type) {
    margin-top: 100px;
  }
`
