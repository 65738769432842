import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import logo from 'assets/prb-logo.png'
import { Link } from 'react-router-dom'

const AdminMenu = () => {
  return (
    <Wrapper>
      <Logo src={logo} alt='prb' />
      <LinksWrapper>
        <StyledLink to='/adminAward/kandydaci'>Nominowani kandydaci</StyledLink>
        <StyledLink to='/adminAward/glosujacy'>Kapituła</StyledLink>
      </LinksWrapper>
    </Wrapper>
  )
}

export default AdminMenu

const Wrapper = styled.div`
  width: 250px;
  background: #f2f2f2;
  padding: 15px;
`

const Logo = styled.img`
  width: 150px;
  text-align: center;
  margin-bottom: 50px;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLink = styled(Link)`
  padding: 12px;
  border-radius: 8px;
  transition: all .3s;
  text-decoration: none;
  color: #444;
  font-weight: 400;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`
